<template>
    <div class="fixed bg-white bg-opacity-50 top-0 left-0 z-50 w-full h-full flex items-center justify-center">
        <div class="bg-white p-2 rounded-2xl w-[65%] sm:w-[35%] shadow-2xl">
            <div class="flex justify-center flex-col items-center mx-auto">
                <img class="rounded-full w-16 h-16 md:w-24  md:h-24 object-cover" src="../../assets/mascottePensif.jpg" alt="mascotteTigre">

                <div class="m-4 inline-block">
                    <h1 v-if="props.relance1" class="text-[0.75rem] md:text-md  mx-auto text-center font-bold mb-4 text-black">Vous êtes
                        toujours là
                        😉</h1>
                    <h1 v-if="props.relance2" class="text-[0.75rem] md:text-md  mx-auto text-center font-bold mb-4 text-black">Je vous laisse reprendre la
                        main
                        quand vous êtes dispo 😉. A tout de suite ! </h1>
                    <h1 v-if="props.relance3" class="text-[0.75rem] md:text-md  mx-auto text-center font-bold mb-4 text-black">Time out… désolé, il faut
                        reprendre
                        le parcours 😊</h1>
                </div>
            </div>
            <div class="flex justify-center gap-10">
                <button @click="relance()" v-if="props.relance1"
                    class="bg-emerald-500 text-white text-[0.75rem] md:text-sm font-bold w-fit h-fit px-6 py-2 rounded-full items-center disabled:cursor-not-allowed">
                    OUI
                </button>
                <button @click="relance()" v-if="props.relance2"
                    class="bg-emerald-500 text-white text-[0.75rem] md:text-sm font-bold w-fit h-fit px-6 py-2 rounded-full items-center disabled:cursor-not-allowed">
                    OK
                </button>
                <button @click="relanceOK()" v-if="props.relance3"
                    class="bg-emerald-500 text-white text-[0.75rem] md:text-sm font-bold w-fit h-fit px-6 py-2 rounded-full items-center disabled:cursor-not-allowed">
                    OK
                </button>

            </div>
        </div>
    </div>
</template>


<script setup>
/***
 * Importations des ressources 
 */
import {defineEmits, defineProps } from 'vue';



/***
 * Déclaration des variables, des props et  des émits
 */
const emit = defineEmits(['close']);

const props = defineProps(['relance1', 'relance2', 'relance3'])



/***
 * Fonctions  locales
 */

 // Fermet le modal après le click de l'utilisateur
const relance = () => {
    emit('close')
}

// Permet d'actualiser  la page après avoir cliqué sur "OK"
const relanceOK = () => {
    emit('close')
    window.location.reload()
}
</script>

<style></style>