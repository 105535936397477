<template>

  <div>

    <router-view></router-view>
  </div>

</template>

<script setup>


</script>
<style>
html {

  /* background-image: linear-gradient(
  to right, 
  #e8ffd7 10%, 
  #c1fed0 30%, 
  #aefdcd 90%
); */
/* 
background-color: #128c7e;
   background-image: linear-gradient(to right,
      #e8ffd7 5%,
      #a3e6bb 40%,
      #9fe6b9 50%,
      #64c9b5 95%
      ); */


   background-image: linear-gradient(to right,
      #e8ffd7 10%,
      #cdfad9 30%,
      #c0eecb 50%,
      #aefdcd 90%);

  /* background-color: #4e764f;
  background-image: linear-gradient(to right,
      #e8ffd7b3 10%,
      #c1fed0b3 30%,
      #aefdcdb3 90%);   */



  /* Dégradé demander */
  /* background: linear-gradient(to right,#cfd21f ,#e7f09f  ); */

  height: 100%;
}

/* body {
  height: 100%;
  margin: 0;
} */
</style>