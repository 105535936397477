<template>
    <div
        class="z-50 flex flex-col justify-center bg-white md:flex-row md:justify-between md:shadow-md xl:shadow-xl 2xl:shadow-2xl md:sticky md:top-0 mb-[1rem]">
        <div class="md:flex sm:flex   sm:items-center sm:justify-between  sm:mr-8 md:items-center">
            <div class="text-center md:flex md:justify-center md:items-center md:flex-col md:w-[23rem] lg:w-80 sm:border-r-2 sm:ml-2 sm:pr-2 m-1  md:m-2">
                <p class="font-bold text-[13px] lg:[0.85] md:text-xl">Meilleures offres énergies</p>
                <p class="text-[11px] lg:text-[0.80rem] md:w-[80%] mt-2">Trouvez l'offre Energie qui correspond le mieux à vos besoins !</p>
            </div>
            <div class="text-center sm:border-none xl:border-none 2xl:border-none border-t-2 p-1 md:p-4">
                <p class="text-[11px] lg:text-[15px] md:text-[15px]"><strong>Léo, votre bot Energie</strong></p>
                <div class="flex items-center justify-center  gap-3">
                    <p class="bg-green-600 border-2 rounded-full w-3 h-3 sm:ml-[-65%]"></p>
                    <p class="text-[11px] sm:ml-[-6%]">En ligne</p>
                </div>
            </div>
        </div>
        <div class="border-t-2 xl:border-none 2xl:border-none md:border-none md:mt-[-1rem] flex items-center justify-center p-1 md:p-2">
            <p class="text-red-500 text-[10px] lg:text-[0.75rem] md:mr-4  xl:mt-8 2xl:mt-8">Vous êtes sur un Espace sécurisé </p>
        </div>
    </div>
    
  

</template>

<script setup>

</script>

<style></style>