<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <!-- Entête de l'interface de conversation -->
  <chatHeader />
  <main ref="pageContainer"
    class="m-0 mt-[-1rem] sm:w-[70vw] sm:mx-auto xl: w-full overflow-hidden md:w-[65vw] xl:w-[60vw] 2xl:w-[59vw] md:mx-auto lg:mx-auto">
    <!-- Interface de discussion -->
    <div class="p-3 mb-[10rem] md:mr-[2%] lg:mr-[5%] 2xl:mr-[6%]">
      <div class="">
        <!-- partie pour la gestion du fil de discussion -->
        <section v-for="(message, index) in chatHistory" :key="index" class="flex">
          <!-- partie affichant les messages du bot -->
          <section v-if="message.role == 'bot'" class="">
            <!-- affiche les phrases d'introduction du bot -->
            <div v-show="message.welcomeMsg.length > 0" class="">
              <div class="flex flex-col gap-1 contenu">
                <div class="flex items-center gap-6" v-for="(value, index) in message.welcomeMsg" :key="index">
                  <!-- <img class="del animation_chat w-8 h-8 lg:w-10 lg:h-10 m-0" src="../assets/leo.png" alt="mascotteTigre"> -->
                  <p class="bg-[#05dd5a] w-8 h-8 lg:w-10 lg:h-10 opacity-0"></p>
                  <p
                    class="chat-bubble w-[80vw] sm:w-[58vw] md:w-[54vw]   xl:w-[51vw] 2xl:[52vw]  p-2 text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base l">
                    {{ value }} </p>
                </div>
              </div>
            </div>
            <!-- affiche les affirmations et la question posées par le bot -->
            <div class="message mt-[0.25rem] m-0 flex items-center gap-6">
              <div class="flex flex-col gap-1">
                <div v-if="message.affirmation.length > 0" class="flex gap-6 ">
                  <p class="bg-[#05dd5a] w-8 h-8 lg:w-10 lg:h-10 opacity-0"></p>
                  <div class="flex flex-col gap-1">
                    <p class="chat-bubble w-[80vw] sm:w-[58vw]  p-2 text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base  md:w-[54vw]   xl:w-[51vw] 2xl:[52vw] "
                      v-for="(value, index) in message.affirmation" :key="index">
                      {{ value }}</p>
                  </div>
                </div>
                <div class="flex gap-6">
                  <img class="w-8 h-8 lg:w-10 lg:h-10 object-cover m-0" src="../assets/leo.png" alt="mascotteTigre">
                  <p class="chat-bubble w-[80vw] sm:w-[58vw]  p-2 text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base  md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] "
                    :class="{ 'w-[78vw], ': message.question == 'Actuellement, à combien s\'élève vos factures mensuelles ?  🙂' }">
                    {{ message.question }}</p>
                </div>
              </div>
            </div>
          </section>
          <!-- partie affichant les messages de l'utilisateur -->
          <section v-else class="message animation_chat message-right mt-2 mb-1 w-full">
            <div
              class="chat-bubble_answer text-white p-2 px-4 text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base bg-gradient-to-r from-emerald-600 to-emerald-500 ">
              {{ message.content }}
            </div>
          </section>
        </section>
        <!-- partie pour l'ajout dynamique des questions et suggestions de réponse du bot -->
        <div class="">
          <!-- ***** Message d'ouverture de Léo (Début) **** -->
          <section v-show="beginMsg.length > 0" class="flex flex-col gap-1">
            <!--***** Premier message *****-->
            <section v-if="show1" class="flex flex-col gap-3 p-0">
              <div class="flex items-center gap-6">
                <img :class="{ 'opacity-0': show2 }" class="opacity-1 del animation_chat w-8 h-8 lg:w-10 lg:h-10 m-0"
                  src="../assets/leo.png" alt="mascotteTigre">
                <p
                  class="chat-bubble animation_chat w-[80vw] sm:w-[58vw] md:w-[60vw] xl:w-[51vw] 2xl:[52vw]  p-2 text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base ">
                  Bonjour, je suis Léo, votre bot Energie.</p>
              </div>
              <Chat_loader v-if="!show2 && !show3 && !showBotAnswer" class="ml-16" />
            </section>
            <!--***** Deuxième message *****-->
            <section v-if="show2" class="flex flex-col gap-3">
              <div class="flex items-center gap-6">
                <img :class="{ 'opacity-0': show3 }" class="opacity-1 animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
                  src="../assets/leo.png" alt="mascotteTigre">
                <p
                  class="chat-bubble animation_chat w-[80vw] sm:w-[58vw] md:w-[60vw] xl:w-[51vw] 2xl:[52vw]  p-2 text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base ">
                  Vous recherchez l’offre énergie qui vous convient ? C’est bien là !!!</p>


              </div>
              <Chat_loader v-if="!show3 && show2 && show1" class="ml-16" />
            </section>
            <!--***** Troisième message *****-->
            <section v-if="show3" class="flex flex-col gap-3">
              <div class="flex items-center gap-6">
                <img :class="{ 'opacity-0': showBotAnswer }"
                  class="opacity-1 animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0" src="../assets/leo.png"
                  alt="mascotteTigre">
                <p
                  class="chat-bubble animation_chat w-[80vw] sm:w-[58vw] md:w-[60vw] xl:w-[51vw] 2xl:[52vw]  p-2 text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base ">
                  Faisons connaissance...</p>
              </div>
              <Chat_loader v-if="show3 && show2 && show1 && !showBotAnswer" class="ml-16" />
            </section>
          </section>
          <!-- ***** Message d'ouverture de Léo (Fin) **** -->
          <!-- cette partie affichera les messages du bot -->
          <div class="">
            <div class="">
              <Chat_loader v-if="showAnswerLoader" class="ml-16" />
            </div>
            <!-- ***** Question et suggestion de réponses du bot (Début) **** -->
            <section v-if="showBotAnswer" class="">
              <div>
                <!-- affiche les affirmations du bot -->
                <section v-if="showBotAnswer" v-show="botAnswer.length > 0">
                  <section class="flex flex-col gap-1 mt-[0.25rem] ">
                    <section class="flex flex-col gap-3 ">
                      <div v-if="showAffirmation1" class="flex items-center gap-6">
                        <img :class="{ 'opacity-0': showAffirmation2 }" class="del w-8 h-8 lg:w-10 lg:h-10 m-0"
                          src="../assets/leo.png" alt="mascotteTigre">
                        <p
                          class="chat_bubble_question animation_chat w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] p-2 text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base ">
                          {{ showAffirmation }}</p>
                      </div>
                      <Chat_loader v-if="!showAffirmation2 && showAffirmation1" class="ml-16" />
                    </section>
                    <section class="flex flex-col gap-3 ">
                      <div v-if="showAffirmation2 && botAnswer.length > 1" class="flex items-center gap-6">
                        <img :class="{ 'opacity-0': showQuestion }"
                          class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0" src="../assets/leo.png"
                          alt="mascotteTigre">
                        <p
                          class="chat_bubble_question animation_chat w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] p-2 text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base ">
                          On continue à faire connaissance...
                        </p>
                      </div>
                      <Chat_loader v-if="showAffirmation1 && showAffirmation2 && !showQuestion" class="ml-16" />
                    </section>
                  </section>
                  <section v-if="showAffirmation3" class="flex flex-col gap-3">
                    <div class="flex items-center gap-6">
                      <img :class="{ 'opacity-0': showQuestion }" class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
                        src="../assets/leo.png" alt="mascotteTigre">
                      <p
                        class="chat_bubble_question animation_chat w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] p-2 text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base ">
                        {{ showAffirmation }}</p>
                    </div>
                    <Chat_loader v-if="!showQuestion && showAffirmation3" class="ml-16" />
                  </section>
                  <section v-if="showAffirmation4" class="flex flex-col gap-3">
                    <div class="flex items-center gap-6">
                      <img :class="{ 'opacity-0': showQuestion }" class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
                        src="../assets/leo.png" alt="mascotteTigre">
                      <p
                        class="chat_bubble_question animation_chat w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] p-2 text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base ">
                        {{ showAffirmation }}</p>
                    </div>
                    <Chat_loader v-if="!showQuestion && showAffirmation4" class="ml-16" />
                  </section>
                  <section class="flex flex-col gap-1">
                    <section v-if="showAffirmation5" class="flex flex-col gap-3">
                      <div class="flex items-center gap-6">
                        <img :class="{ 'opacity-0': showAffirmation6 }"
                          class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0" src="../assets/leo.png"
                          alt="mascotteTigre">
                        <p
                          class="chat_bubble_question animation_chat w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] p-2 text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base ">
                          Merci pour toutes ces informations.</p>
                      </div>
                      <Chat_loader v-if="showAffirmation5 && !showAffirmation6 && !showAffirmation7 && !showQuestion"
                        class="ml-16" />
                    </section>
                    <section v-if="showAffirmation6" class="flex flex-col gap-3">
                      <div class="flex items-center gap-6">
                        <img :class="{ 'opacity-0': showAffirmation7 }"
                          class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0" src="../assets/leo.png"
                          alt="mascotteTigre">
                        <p
                          class="chat_bubble_question animation_chat w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] p-2 text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base ">
                          Je recherche pour vous la meilleure offre.
                        </p>
                      </div>
                      <Chat_loader v-if="showAffirmation5 && showAffirmation6 && !showAffirmation7 && !showQuestion"
                        class="ml-16" />
                    </section>
                    <section v-if="showAffirmation7" class="flex flex-col gap-3">
                      <div class="flex items-center gap-6">
                        <img :class="{ 'opacity-0': showAffirmation8 }"
                          class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0" src="../assets/leo.png"
                          alt="mascotteTigre">
                        <p
                          class="chat_bubble_question animation_chat w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] p-2 text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base ">
                          Dernier virage !</p>
                      </div>
                      <Chat_loader
                        v-if="showAffirmation5 && showAffirmation6 && showAffirmation7 && !showAffirmation8 && !showQuestion"
                        class="ml-16" />
                    </section>

                    <section v-if="showAffirmation8" class="flex flex-col gap-3">
                      <div class="flex items-center gap-6">
                        <img :class="{ 'opacity-0': showQuestion }"
                          class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0" src="../assets/leo.png"
                          alt="mascotteTigre">
                        <p
                          class="chat_bubble_question animation_chat w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] p-2 text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base ">
                          Bonne nouvelle ! Nous avons trouvé l’offre qui répond à vos attentes.</p>
                      </div>
                      <Chat_loader
                        v-if="showAffirmation5 && showAffirmation6 && showAffirmation7 && showAffirmation8 && !showQuestion"
                        class="ml-16" />
                    </section>

                  </section>
                </section>
                <div class="animation_chat  message-left flex items-center gap-6">
                  <img v-if="showQuestion" class="del animation_chat w-8 h-8 lg:w-10 lg:h-10 m-0"
                    src="../assets/leo.png" alt="mascotteTigre">
                  <div v-if="showQuestion"
                    class="chat_bubble_question w-[80vw] sm:w-[58vw]  md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] mt-[0.25rem] p-2 text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base "
                    :class="{ 'mt-0': isCodePostalForm }">
                    <p class="">{{ firstQuestion }}</p>
                    <div class="">
                      <!-- Formulaire Code Postal -->
                      <div v-if="isCodePostalForm">
                        <p class="mt-2">Cela me permet de me concentrer sur les offres disponibles pour vous.</p>
                        <div class="inline-block bg-white rounded-md p-4 m-1  2xl:w-[50vw] ] w-[260px]">
                          <div>
                            <p>(<span
                                class="text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base font-bold">*</span>)
                              Champ
                              obligatoire</p>
                          </div>
                          <div class="flex justify-center mt-3 border-2 w-full md:w-[40vw] rounded-lg pb-4">
                            <div class="mt-3 ">
                              <p><span
                                  class="text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base font-bold">*</span>
                                Code Postal
                              </p>
                              <input v-model="codePostal"
                                class="2xl:h-[2rem] border-2 border-teal-400 w-full md:w-[30vw]  mt-2 pl-2"
                                placeholder="75001">
                              <div class="error-message mt-2" v-if="error">{{ error }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Formulaire découverte offre -->
                      <div v-else-if="isOfferForm"
                        class="inline-block bg-white  p-4 m-1 2xl:w-[48vw] md:w-[52vw] xl:w-[45vw] w-[70vw] sm:w-[55vw]">
                        <div>
                          <p>(<span
                              class="text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base font-bold">
                              * </span>)
                            Champs
                            obligatoires</p>
                        </div>
                        <div class="flex flex-col mt-3 w-full ">
                          <!-- Form1 -->
                          <div class="border-2 rounded-lg p-3">
                            <div class="flex flex-wrap gap-2 ">
                              <div class="mb-4 w-full md:w-[49%] lg:w-[49%]">
                                <label for="email"
                                  class="block text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base font-medium text-gray-600"><span
                                    class="text-black text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base font-bold">*</span>
                                  Email :</label>
                                <input id="email"
                                  class="mt-1 p-1 border-2 border-solid rounded-md  w-full text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base "
                                  placeholder="Veuillez entrer votre mail" type="email" v-model="email_client">
                                <p v-if="emailError"
                                  class="text-red-500 text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base">
                                  {{ emailError
                                  }}</p>
                              </div>
                              <div class="mb-4 w-full md:w-[49%] lg:w-[49%]">
                                <label for="telephone"
                                  class="block text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base font-medium text-gray-600"><span
                                    class="text-black text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base font-bold">*</span>
                                  Téléphone
                                  :</label>
                                <input id="telephone"
                                  class="mt-1 p-1 border-2 border-solid rounded-md  w-full text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base"
                                  placeholder="veuillez entrer votre numéro" type="tel" v-model="numero_client">
                                <p v-if="numeroError"
                                  class="text-red-500 text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base">
                                  {{ numeroError
                                  }}</p>
                              </div>
                            </div>
                            <div class="flex justify-center">
                              <button v-if="showSuivantButton" @click="nextStep()"
                                class="bg-gradient-to-r from-emerald-500 to-emerald-600 text-white m-1 mb-1 p-1 px-2 rounded-md">Suivant</button>
                            </div>
                          </div>
                          <!-- Form2 -->
                          <div v-if="currentStep" class="mt-[1.5rem] text-justify flex flex-col mb-1">
                            <div class="mb-[0.75rem]">
                              <input id="firstAgreement" type="checkbox" class="mr-2" v-model="firstAgreement">
                              <label for="firstAgreement"
                                class="text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base">
                                En cliquant sur <span class="underline">« Je découvre mon offre »</span> vous
                                donnez
                                votre accord pour être contacté(e) par e-mail, téléphone, sms, whatsapp par VippData
                                ou
                                l'un
                                de ses partenaires pour une présentation personnalisée, conformément à nos <span
                                  class="underline"><a target="_blank" href="/conditionsgenerales"
                                    class="underline text-red-400">CGU</a></span>.
                              </label>
                            </div>
                            <div
                              class="text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base mb-[0.75rem]">
                              Vos données sont recueillies pour permettre à VippData et à ses partenaires de vous
                              contacter pour vous présenter une proposition adaptée à vos besoins. Pour en savoir
                              plus
                              sur
                              la gestion de vos données personnelles et l'exercice de vos droits, rendez-vous sur la
                              page
                              <a class=" underline break-words" target="_blank"
                                href="https://meilleuresoffresenergies.fr/politiqueConfidentialite">
                                https://meilleuresoffresenergies.fr
                              </a>
                            </div>
                            <div
                              class="text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base font-bold text-center">
                              Ce contact est
                              entièrement gratuit et sans aucun
                              engagement.</div>
                          </div>
                          <p v-if="showCGUmsg"
                            class="text-red-600 text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base">
                            Merci de prendre
                            connaissance de nos
                            <span class="underline">CGU</span> avant d'accéder à votre offre.
                          </p>
                        </div>
                      </div>
                      <!-- Affiche les réponses basiques -->
                      <!-- <section class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 mt-4"> -->
                      <section class="flex flex-wrap justify-center items-center  gap-6 lg:gap-10 mt-4">
                        <div class="flex flex-col justify-center md:items-center" :disabled="chooseAnswer"
                          v-for="(value, index) in suggested_answers" :key="index">
                          <img v-if="value.answerImg != null" class="mx-auto object-cover w-[60px] h-[60px]"
                            :src="value.answerImg" alt="mascotteTigre">

                          <button v-if="showAnswerButton && value.answer === 'Je découvre mon offre'"
                            @click="getAnswerChosen(value.answer)" :disabled="isDisabled"
                            :class="{ 'hover:bg-red-500': !firstAgreement, 'bg-emerald-600': true }"
                            class="transition-all duration-500 ease-out font-semibold text-white p-2 w-[120px] md:w-[130px] lg:w-[200px] rounded-md text-[0.70rem] lg:text-[0.80rem]"
                            @mouseover="handleInteraction" @mouseleave="handleMouseLeave"
                            @touchstart="handleInteraction" @touchend="handleMouseLeave">

                            <span>{{ displayText }}</span>
                          </button>


                          <button v-if="showAnswerButton && value.answer !== 'Je découvre mon offre'"
                            @click="getAnswerChosen(value.answer)"
                            class="bg-emerald-600  transition-all duration-500 ease-out font-semibold text-white p-1 w-[100px] md:w-[100px] lg:w-[170px] rounded-md text-[0.70rem] lg:text-[0.80rem]">
                            {{ value.answer }}
                          </button>

                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- ***** Question et suggestion de réponses du bot (Fin) **** -->
          </div>
        </div>
        <div class="flex flex-col gap-1 mt-[0.25rem]">
          <section class="flex flex-col gap-3" v-if="messageOffer1">
            <div class="flex items-center gap-6">
              <img :class="{ 'opacity-0': offerEnie }" class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
                src="../assets/leo.png" alt="mascotteTigre">
              <p
                class="chat_bubble_question animation_chat w-[80vw] sm:w-[58vw] md:w-[54vw]  p-2 text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base">
                Patience... votre offre apparaît d'ici quelques
                secondes...</p>
            </div>
            <Chat_loader v-if="currentMessage === 1" class="ml-16" />
          </section>

          <section class="flex flex-col gap-3" v-if="offerEnie">
            <div class="flex items-center gap-6">
              <img :class="{ 'opacity-0': messageOffer2 }" class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0 "
                src="../assets/leo.png" alt="mascotteTigre">
              <div class="">
                <p
                  class="chat_bubble_question animation_chat p-2 w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base">
                  L'offre la plus adaptée à votre recherche est chez :
                  <img class="mx-auto mt-[1rem] w-[88px] h-[88px]" src="../assets/logo_eni.png" alt="mascotteTigre">
                </p>
              </div>
            </div>
            <Chat_loader v-if="currentMessage === 2" class="ml-16 " />
          </section>

          <section class="flex flex-col gap-3" v-if="messageOffer2">
            <div class="flex items-center gap-6">
              <img :class="{ 'opacity-0': messageOffer3 }" class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0 "
                src="../assets/leo.png" alt="mascotteTigre">
              <div
                class="animation_chat1 chat-bubble p-2 text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base w-[80vw] md:w-[54vw] xl:w-[51vw] 2xl:[52vw]  ">
                <p class=""> Vous profiterez :</p>
                <ul class="ml-5 mr-4 mt-3" v-if="energieElectricite">
                  <li class="text-[#128c7e] font-semibold"><span class="font-bold pr-1">.</span>
                    D’un prix fixe électricité du kWh HT et d’une réduction moyenne de 10% par rapport au tarif
                    réglementé actuel.
                  </li>
                  <li class="text-[#128c7e] font-semibold"><span class="font-bold pr-1">.</span>
                    De 100% énergie verte.</li>
                </ul>
                <ul class="ml-5 mr-4 mt-3" v-if="energieGaz">
                  <li class="text-[#128c7e] font-semibold"><span class="font-bold pr-1">.</span>
                    D’un prix gaz fixe du kWh HT et d’une réduction moyenne de 11% par rapport au tarif réglementé
                    actuel.</li>
                  <li class="text-[#128c7e] font-semibold"><span class="font-bold pr-1">.</span>
                    De 100% énergie verte</li>
                </ul>
                <ul class="ml-5 mr-4 mt-3" v-if="electricityGaz">
                  <li class="text-[#128c7e] font-semibold"><span class="font-bold pr-1">.</span>
                    D’un prix fixe du kWh HT et d’une réduction moyenne de 10% par rapport au tarif réglementé actuel.
                  </li>
                  <li class="text-[#128c7e] font-semibold"><span class="font-bold pr-1">.</span>
                    De 100% énergie verte</li>
                </ul>
              </div>
            </div>
            <Chat_loader v-if="currentMessage === 3" class="ml-16" />
          </section>

          <section class="flex flex-col gap-3" v-if="messageOffer3">
            <div class="flex items-center gap-6">
              <img :class="{ 'opacity-0': messageOffer4 }" class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0 "
                src="../assets/leo.png" alt="mascotteTigre">
              <div class="">
                <p
                  class="chat_bubble_question animation_chat p-2 w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base">
                  Je transmets vos réponses à notre conseiller, Il vous appelle d’ici quelques minutes…
                </p>
              </div>
            </div>
            <Chat_loader v-if="currentMessage === 4" class="ml-16 " />
          </section>
          <section class="flex flex-col gap-3" v-if="messageOffer4">
            <div class="flex items-center gap-6">
              <img :class="{ 'opacity-0': messageOffer5 }" class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0 "
                src="../assets/leo.png" alt="mascotteTigre">
              <div class="">
                <p
                  class="chat_bubble_question animation_chat p-2 w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base">
                  Surtout restez près de votre téléphone 📞
                </p>
              </div>
            </div>
            <Chat_loader v-if="currentMessage === 5" class="ml-16 " />
          </section>
          <section class="flex flex-col gap-3" v-if="messageOffer5">
            <div class="flex items-center gap-6">
              <img :class="{ 'opacity-0': messageOffer6 }" class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0 "
                src="../assets/leo.png" alt="mascotteTigre">
              <div class="">
                <p
                  class="chat_bubble_question animation_chat p-2 w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base">
                  Merci de vous connecter à l’espace client de votre fournisseur actuel ou d’avoir une facture à portée
                  de main : notre conseiller sera plus précis dans sa proposition.
                </p>
              </div>
            </div>
            <Chat_loader v-if="currentMessage === 6" class="ml-16 " />
          </section>
          <section class="flex flex-col gap-3" v-if="messageOffer6">
            <div class="flex items-center gap-6">
              <img :class="{ 'opacity-0': messageOffer7 }" class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0 "
                src="../assets/leo.png" alt="mascotteTigre">
              <div class="">
                <p
                  class="chat_bubble_question animation_chat p-2 w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base">
                  Et quant à moi, je vous souhaite une excellente journée et, bien sûr une nouvelle expérience Energie !
                  😉
                </p>
              </div>
            </div>
            <Chat_loader v-if="currentMessage === 7" class="ml-16 " />
          </section>
          <section class="flex flex-col gap-3" v-if="messageOffer7">
            <div class="flex items-center gap-6">
              <img :class="{ 'opacity-0': messageOffer8 }" class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0 "
                src="../assets/leo.png" alt="mascotteTigre">
              <div class="">
                <p
                  class="chat_bubble_question animation_chat p-2 w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base">
                  Soyez prêt(e) à décrocher pour profiter de votre offre au plus vite.
                </p>
              </div>
            </div>
            <Chat_loader v-if="currentMessage === 8" class="ml-16 " />
          </section>

          <!-- Partie affichant 'Offre non éligible' -->
          <section class="flex flex-col gap-3" v-if="nonEligible">
            <div class="flex items-center gap-6">
              <img :class="{ 'opacity-0': nonEligible1 }" class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0 "
                src="../assets/leo.png" alt="mascotteTigre">
              <div class="">
                <p
                  class="chat_bubble_question animation_chat p-2 w-[80vw] font-semibold text-red-600 sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base">
                  Désolé, nous n’avons actuellement pas d’offre disponible dans votre commune.
                </p>
              </div>
            </div>
            <Chat_loader class="ml-16 " v-if="currentEniMessage" />
          </section>
          <section class="flex flex-col gap-3" v-if="nonEligible1">
            <div class="flex items-center gap-6">
              <img :class="{ 'opacity-0': nonEligible2 }" class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0 "
                src="../assets/leo.png" alt="mascotteTigre">
              <div class="">
                <p
                  class="chat_bubble_question animation_chat p-2 w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base">
                  Nous vous remercions de votre visite sur notre site ! Restez à l'affût, nos offres évoluent
                  constamment. Revenez nous voir pour ne rien manquer !
                </p>
              </div>
            </div>
            <Chat_loader class="ml-16 " v-if="currentEniMessage1" />
          </section>
          <section class="flex flex-col gap-3" v-if="nonEligible2">
            <div class="flex items-center gap-6">
              <img :class="{ 'opacity-0': messageOffer8 }" class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0 "
                src="../assets/leo.png" alt="mascotteTigre">
              <div class="">
                <div
                  class="chat_bubble_question animation_chat p-2 w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] text-[0.75rem] md:text-[0.80rem] lg:text-base xl:text-base 2xl:text-base">
                  <p>Vous pouvez encore économiser sur votre forfait internet. Souhaitez-vous profitez de nos offres ?
                  </p>
                  <p class="text-center p-1 mt-3"><a href="https://meilleursforfaitsinternet.fr/" target="_blank"
                      class="bg-[#128c7e] rounded-lg p-1 px-2 text-white"> OUI </a></p>
                </div>
              </div>
            </div>
            <Chat_loader class="ml-16 " v-if="currentEniMessage2" />
          </section>

          <section class="flex items-center gap-6" v-if="messageOffer8">
            <img class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0" src="../assets/leo.png" alt="mascotteTigre" />
            <chatbotPartage />
          </section>
        </div>
      </div>
    </div>
    <!-- Modal de relance de l'utilisateur -->
    <relanceModal v-if="openRelanceModal" @close="closeRelanceModal()" :relance1="relance1" :relance2="relance2"
      :relance3="relance3" />
    <!-- Modal d'au revoir -->
    <GoodByeModal @close="closeGoodByeModal()" v-if="openGoodByeModal" />
    <!-- Modal de consentement aux cookies -->
    <cookiesModal class="cookiesAnimation" @close="closeCookiesModal()" v-if="openCookiesModal" />
    <img @click="showCookiesModal()" v-if="!openCookiesModal && showIconCookies"
      class="fixed bottom-[6%] lg:bottom-[8%] sm:bottom-[8%] md:bottom-[16%] w-10 md:w-12  lg:w-18 h-10 md:h-12  lg:h-18 left-0 -[10%]"
      src="../assets/tigre_cookies.png" alt="tigre_cookies">
  </main>
  <!-- le pied de page de l'interface de conversation -->
  <chatFooter />
  <!-- Loader au chargement de la page -->
  <Initial_loader v-if="showInitialLoader" class="loaderStyle" />
</template>

<script setup>

/***
 * Importations des ressources nécessaires
 */
import { onMounted, ref, watch } from 'vue';
import config from "@/main.js";
import axios from 'axios';
// Importation du loader à afficher au chargement de la page
import Initial_loader from '@/components/First_loader.vue'
// Importation du loader à afficher lorsqu'un message est en cours de chargement
import Chat_loader from '@/components/chat_loader.vue'
import chatbotPartage from '@/components/chatbot/chatbotPartage.vue';

import chatHeader from '@/components/chatbot/chatbotHeader.vue';
import chatFooter from '@/components/chatbot/chatbotFooter.vue';
import relanceModal from '@/components/chatbot/RelanceModal.vue';
import cookiesModal from '@/components/chatbot/CookiesModal.vue';
import GoodByeModal from '@/components/chatbot/GoodByeModal.vue';


/********************  Modal de relance *********************/


/***
 * Déclaration des variables
 */
const relance1 = ref(false)
const relance2 = ref(false)
const relance3 = ref(false)
const openRelanceModal = ref(false)
const showIconCookies = ref(false)
let clearInactivity = ref(null)
let isDisabled = ref(true)


//données pour les formulaires
const emailError = ref('');
const numeroError = ref('');
const showError = ref('');
const email_client = ref(null)
const numero_client = ref(null)
const firstAgreement = ref(false)
const fournisseur_elec = ref(null)
const fournisseur_gaz = ref(null)
const motivation = ref(null)
// const secondAgreement = ref(null)
const codePostal = ref(null)



// Fermer  le modal de relance
const closeRelanceModal = () => {
  openRelanceModal.value = false
}

const openCookiesModal = ref(false);
setTimeout(() => {
  openCookiesModal.value = true
}, 10000);

/***
 * Méthodes
 */

// L'ensemble des fonctions suivantes sont pour gérer l'interaction des utilisateurs sur le bouton je découvre mon offre
const displayText = ref('Je découvre mon offre');
function handleInteraction() {
  if (isDisabled.value) {
    displayText.value = 'Veuiller cocher la case des CGU';
  } else {
    displayText.value = 'Je découvre mon offre';
  }
}

function handleMouseLeave() {
  if (!isDisabled.value && firstAgreement.value) {
    displayText.value = 'Je découvre mon offre';
  }
  else if (isDisabled.value && !firstAgreement.value) {
    displayText.value = 'Je découvre mon offre';
  }
}

watch(firstAgreement, (newVal) => {
  if (newVal) {
    isDisabled.value = false;
  } else {
    isDisabled.value = true;
  }
});

watch(isDisabled, (newVal) => {
  if (!newVal) {
    displayText.value = 'Je découvre mon offre';
  }
});

///------fin------///

// Affiche le modal des cookies
const showCookiesModal = () => {
  openCookiesModal.value = true
  showIconCookies.value = false
}
// Fermer le modal des cookies
const closeCookiesModal = () => {
  openCookiesModal.value = false
  showIconCookies.value = true
}

const openGoodByeModal = ref(false);

const closeGoodByeModal = () => {
  openGoodByeModal.value = false
  clearTimeout(clearInactivity.value)
  clearTimeout(setIntervalDelete.value);

}

const offerEnie = ref(false)
const messageOffer1 = ref(false)
const messageOffer2 = ref(false)
const messageOffer3 = ref(false)
const messageOffer4 = ref(false)
const messageOffer5 = ref(false)
const messageOffer6 = ref(false)
const messageOffer7 = ref(false)
const messageOffer8 = ref(false)
const nonEligible = ref(false)
const nonEligible1 = ref(false)
const nonEligible2 = ref(false)

const show1 = ref(true)
const show2 = ref(false)
const show3 = ref(false)
const showAnswerButton = ref(true)
const showQuestion = ref(false)
const messageFinal = ref(false)


setTimeout(() => {
  show2.value = true;
}, 4000);
setTimeout(() => {
  show3.value = true;
}, 6000);


const currentStep = ref(false);
const nextStep = () => {
  // Validation logic
  if (!email_client.value) {
    emailError.value = 'Veuillez entrer un mail.';
  } else if (!isEmailValid(email_client.value)) {
    emailError.value = 'Veuillez saisir une adresse e-mail valide.';
  } else {
    emailError.value = ''
  }

  if (!numero_client.value) {
    numeroError.value = 'Veuillez entrer un numéro à 10 chiffres.';
  } else if (!isValidPhoneNumber(numero_client.value)) {
    numeroError.value = 'Veuillez saisir un numéro de téléphone valide à 10 chiffres .';
  } else {
    numeroError.value = '';
  }



  // Check if all fields are valid
  if (isEmailValid(email_client.value) && isValidPhoneNumber(numero_client.value)) {
    currentStep.value++;
    showAnswerButton.value = true
    showSuivantButton.value = false
    return true
  } else {
    currentStep.value = false;
    showAnswerButton.value = false
    return false
  }
}

const setIntervalDelete = ref(null)
const showSuivantButton = ref(true)

// variable de controle du scrool
const pageContainer = ref(null);

// Variable affichant ou non le loader initial
const showInitialLoader = ref(true)
// Variable affichant ou non les message du bot
const showBotAnswer = ref(false)
// Variable affichant ou non le loader lors du chargement d'un message
const beginMsg = ref(['  Bonjour, je suis Léo, votre bot Energie.', "Vous recherchez l’offre énergie qui vous convient ? C’est bien là !!!", "Faisons connaissance..."])
const endMsg = ref(null)
const firstQuestion = ref(' En plus de sécuriser votre budget, pourquoi changer de fournisseur ?')

const suggested_answers = ref([
  { answer: 'Une énergie verte', answerImg: require('@/assets/energie_verte.png') },
  { answer: 'Un service client disponible', answerImg: require('@/assets/conseiller.png') },
  { answer: 'J\'emménage', answerImg: require('@/assets/emmenagement.png') },
  { answer: 'Autre', answerImg: require('@/assets/logo_autre.png') },
])
const error = ref(null)

const answerChosen = ref(null)
const conversation_id = ref(null)
const visitor_id = ref(null)
const botAnswer = ref([])

const isCodePostalForm = ref(false)
const isOfferForm = ref(false)
const showCGUmsg = ref(false)

const historyMessage = ref({})
const chatHistory = ref([])
const count = ref(0)

// const modalAdsl = ref(false)
const modalOui = ref(false)

const offer = ref(null)
// const conversation_modele = ref(null)
const weight = ref(0)
const goNext = ref(false)

const checkInactivity = () => {
  // Vérifier si le compteur d'inactivité a atteint la limite
  if (count.value >= 300) {  // 300 secondes (5 minutes) d'inactivité, ajustez selon vos besoins
    openRelanceModal.value = true;
  }
};

// Utiliser setTimeout pour vérifier l'inactivité après un certain temps
const startInactivityCheck = () => {
  clearInactivity.value = setTimeout(checkInactivity, 300000);
};

const beginDiscussion = (question, answer) => {
  axios.post(`${config.BACK_URL}/init_discussion.php`, {
    question: question,
    answer: answer
  }).then((response) => {
    conversation_id.value = response.data.conversation_id
    visitor_id.value = response.data.visitor_id
  })

}

const energieElectricite = ref(false)
const energieGaz = ref(false)
const electricityGaz = ref(false)

onMounted(() => {
  startInactivityCheck();
  resetActivityTimeout();

  setTimeout(() => {
    showInitialLoader.value = false
  }, 2000);
  setTimeout(() => {

    setTimeout(() => {
      showQuestion.value = true
      showBotAnswer.value = true
    }, 6000);


    endMsg.value = '';
    historyMessage.value = { role: "bot", welcomeMsg: beginMsg.value, question: firstQuestion.value, suggested_answers: suggested_answers.value, affirmation: [] }


    return historyMessage
  }, 2000)


})

// fonction qui gère le scroll automatique du chatbot
const scrollToBottom = () => {
  if (pageContainer.value) {
    pageContainer.value.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  }
};

// fonction utilisée en local
// const verifierCodePostal = async () => {
//   if (/^\d{5}$/.test(codePostal.value)) {
//     error.value = '';
//   } else {
//     error.value = 'Saisissez un code postal à 5 chiffres (exemple: 75001).';
//   }
// };


/**
 * fonction utilisée pour la vérification du code postal
 */
const verifierCodePostal = async () => {
 if (/^\d{5}$/.test(codePostal.value)) {
  try {
    await axios.post(`${config.BACK_URL}/check_code_postal.php`, { codePostal: codePostal.value,  energie: energietype.value})
      .then((response) => {
        if (response.data.statut == 200) {
          error.value = '';
        } else {
          error.value = 'Le code postal saisi n\'existe pas. Veuillez saisir un code existant.';
        }
      });

  } catch (err) {
    error.value = 'Une erreur s\'est produite lors de la vérification du code postal.';
  }
 } else {
  error.value = 'Saisissez un code postal à 5 chiffres (exemple: 75001).';

}
}

const submitForm = () => {

  if (!firstAgreement.value) {
    showError.value = true;
    return false
  } else {
    showError.value = '';
    return true
  }

};

const isEmailValid = (email) => {
  // Utiliser une expression régulière pour vérifier si c'est un email valide
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const isValidPhoneNumber = (phoneNumber) => {
  // const phoneRegex = /^(01|02|03|04|05|06|07|08|09)\d{8}$/
  const phoneRegex = /^(01|02|03|04|05|06|07|08|09)(?!.*00000)\d{8}$/
  return phoneRegex.test(phoneNumber);
};

// let stopExecution = ref(false);

let currentMessage = ref(null)
let currentEniMessage = ref(false)
let currentEniMessage1 = ref(false)
let currentEniMessage2 = ref(false)
// const affirmation = ref("")
const showAffirmation = ref("")
const showAffirmation1 = ref(false)
const showAffirmation2 = ref(false)
const showAffirmation3 = ref(false)
const showAffirmation4 = ref(false)
const showAffirmation5 = ref(false)
const showAffirmation6 = ref(false)
const showAffirmation7 = ref(false)
const showAffirmation8 = ref(false)
const showAnswerLoader = ref(false)
// const disabledButtons = ref({})
let currentEnergySelection = ref(0)
const isMoving = ref(false)
const les_deux = ref(false)
let isElectricitySelected = ref(false);
let isGazSelected = ref(false);
let VariableEni = ref(false);
let energietype = ref(null);
let price = ref(null);
let demenagement = ref(null);

/**
 * Fonction utilisée pour envoyer les informations collectées durant la conversation
 */
const sendLead = async () => {
  axios.post(`${config.BACK_URL}/index.php`, {
    fournisseur_gaz: fournisseur_gaz.value,
    fournisseur_elec: fournisseur_elec.value,
    cout_facture: price.value,
    motivation: motivation.value,
    codePostal: codePostal.value,
    energie_type: energietype.value,
    email: email_client.value,
    telephone: numero_client.value,
    consentement: firstAgreement.value,
    demenagement: demenagement.value != null ? 'Oui' : 'Non',
    lead_id: conversation_id.value,
    provenance: localStorage.getItem('IPSMS')
  }).then((response) => {
    console.log(response);
  })
}

//fonction pour enregistrer les actions de l'utilisateur

const tracking_action = (conversation_id, visitor_id, question, answer) => {
  axios.post(`${config.BACK_URL}/tracking_action.php`, {
    conversation_id: conversation_id,
    visitor_id: visitor_id,
    question: question,
    answer: answer,
  })
}

//Cette fonction permet de renvoyer la question suivante et d'assurer le maintien du fil de la discussion
const getAnswerChosen = async (answer) => {
  clearInterval(setIntervalDelete.value)

  if (answer === 'Electricité') {
    isElectricitySelected.value = true;
  } else if (answer === 'Gaz') {
    isGazSelected.value = true
  }

  if (answer === 'ENI') {
    VariableEni.value = true
  }

  //le bloc suivant est utilisé pour savoir la logique à suivre après le formulaire de code postal
  if (answer == 'SUIVANT') {
    await verifierCodePostal();
    // disabledButtons.value[answer] = true;
    if (error.value == '') {

      answerChosen.value = codePostal.value
      tracking_action(conversation_id.value, visitor_id.value, firstQuestion.value, answer)
      chatHistory.value.push(historyMessage.value)
      chatHistory.value.push({ role: "user", content: "code postal: " + answerChosen.value })
      localStorage.setItem('chatHistory', chatHistory)
      beginMsg.value = []
      showAnswerLoader.value = true
      showQuestion.value = false
      isCodePostalForm.value = false
      showAffirmation4.value = false
      weight.value++
      botAnswer.value = []

      setTimeout(() => {
        showAnswerLoader.value = false
        showBotAnswer.value = true


        if ((VariableEni.value || error.value != '') && isElectricitySelected.value) {
          offerEnie.value = false
          messageOffer1.value = false
          messageOffer2.value = false
          messageOffer3.value = false
          messageOffer4.value = false
          messageOffer5.value = false
          messageOffer6.value = false
          messageOffer7.value = false
          showAnswerButton.value = false
          showBotAnswer.value = true
          currentEniMessage.value = true
          nonEligible.value = true

          setTimeout(() => {
            currentEniMessage.value = false
            currentEniMessage1.value = true
            nonEligible1.value = true

          }, 1500);

          setTimeout(() => {
            currentEniMessage1.value = false
            currentEniMessage2.value = true
            nonEligible2.value = true
          }, 3000);

          setTimeout(() => {
            currentEniMessage2.value = false
            messageOffer8.value = true
          }, 4500);
          window.removeEventListener('mousemove', resetActivityTimeout);
          window.removeEventListener('keydown', resetActivityTimeout);
          return;
        }

        if ((VariableEni.value || error.value != '') && isGazSelected.value) {
          offerEnie.value = false
          messageOffer1.value = false
          messageOffer2.value = false
          messageOffer3.value = false
          messageOffer4.value = false
          messageOffer5.value = false
          messageOffer6.value = false
          messageOffer7.value = false
          showAnswerButton.value = false
          showBotAnswer.value = true
          currentEniMessage.value = true
          nonEligible.value = true

          setTimeout(() => {
            currentEniMessage.value = false
            currentEniMessage1.value = true
            nonEligible1.value = true
          }, 1500);

          setTimeout(() => {
            currentEniMessage1.value = false
            currentEniMessage2.value = true
            nonEligible2.value = true
          }, 3000);

          setTimeout(() => {
            currentEniMessage2.value = false
            messageOffer8.value = true
          }, 4500);
          window.removeEventListener('mousemove', resetActivityTimeout);
          window.removeEventListener('keydown', resetActivityTimeout);
          return;
        }
        if (((fournisseur_elec.value == 'ENI' && fournisseur_gaz.value == 'ENI') || error.value != '') && les_deux.value == true) {
          offerEnie.value = false
          messageOffer1.value = false
          messageOffer2.value = false
          messageOffer3.value = false
          messageOffer4.value = false
          messageOffer5.value = false
          messageOffer6.value = false
          messageOffer7.value = false
          showAnswerButton.value = false
          showBotAnswer.value = true
          currentEniMessage.value = true
          nonEligible.value = true
          setTimeout(() => {
            currentEniMessage.value = false
            currentEniMessage1.value = true
            nonEligible1.value = true
          }, 1500);
          setTimeout(() => {
            currentEniMessage1.value = false
            currentEniMessage2.value = true
            nonEligible2.value = true
          }, 3000);
          setTimeout(() => {
            currentEniMessage2.value = false
            messageOffer8.value = true
          }, 4500);
          window.removeEventListener('mousemove', resetActivityTimeout);
          window.removeEventListener('keydown', resetActivityTimeout);
          return;
        }


        switch (weight.value) {
          case 5:
            window.removeEventListener('mousemove', resetActivityTimeout);
            window.removeEventListener('keydown', resetActivityTimeout);
            botAnswer.value = ['Merci pour toutes ces informations. ', "Je recherche pour vous la meilleure offre.", "Dernier virage !", "Bonne nouvelle ! Nous avons trouvé l’offre qui répond à vos attentes."]
            showAnswerButton.value = false;
            firstQuestion.value = 'Renseignez vos coordonnées et découvrez votre offre !';
            suggested_answers.value = [{ answer: 'Je découvre mon offre', answerImg: null }];
            break;

        }
        // }
        if (botAnswer.value[0] == 'Merci pour toutes ces informations. ') {
          showAffirmation1.value = false
          showAffirmation2.value = false
          showAffirmation3.value = false
          showAffirmation4.value = false
          showAffirmation5.value = true
          setTimeout(() => {
            showAffirmation6.value = true
          }, 1500);
          setTimeout(() => {
            showAffirmation7.value = true
          }, 3000);
          setTimeout(() => {
            showAffirmation8.value = true
          }, 4500);
        }
        setTimeout(() => {
          showQuestion.value = true
        }, botAnswer.value.length * 1500)
        isOfferForm.value = true
        answerChosen.value = null
        historyMessage.value = { role: "bot", welcomeMsg: [], question: firstQuestion.value, suggested_answers: suggested_answers.value, affirmation: botAnswer.value }
        scrollToBottom();
        return historyMessage, isCodePostalForm, isOfferForm
      }, 1500);
    } else {
      isCodePostalForm.value = true
    }

  } else if (answer == 'Je découvre mon offre') {

    if (!email_client.value) {
      emailError.value = 'Veuillez saisir une adresse e-mail valide.';
      return;
    } else if (!isEmailValid(email_client.value)) {
      emailError.value = 'Le format de l\'adresse e-mail est invalide.';
      return;
    } else {
      emailError.value = '';
    }

    if (!numero_client.value) {
      numeroError.value = 'Veuillez entrer un numéro à 10 chiffres.';
      return;
    } else if (!isValidPhoneNumber(numero_client.value)) {
      numeroError.value = 'Le format du numéro de téléphone est invalide.';
      return;
    } else {
      numeroError.value = '';
    }
    //le bloc suivant est utilisé pour savoir la logique à suivre après le formulaire de découverte offre
    if (submitForm()) {
      showQuestion.value = false
      isOfferForm.value = false
      showCGUmsg.value = false

      await sendLead()

      chatHistory.value.push(historyMessage.value)
      chatHistory.value.push({ role: "user", content: answer })
      tracking_action(conversation_id.value, visitor_id.value, firstQuestion.value, answer)
      localStorage.setItem('chatHistory', chatHistory)
      beginMsg.value = []
      showAnswerLoader.value = true
      showBotAnswer.value = false
      setTimeout(() => {
        //charger l'étape suivante
        showAnswerLoader.value = false
        showBotAnswer.value = true
        showQuestion.value = false
        messageFinal.value = true
        weight.value++
        botAnswer.value = []
        if (weight.value) {
          messageOffer1.value = true;
          currentMessage.value = 1;

          setTimeout(() => {
            offerEnie.value = true;
            currentMessage.value = 2;
          }, 6000);

          setTimeout(() => {

            if (isElectricitySelected.value) {
              energieElectricite.value = true;
            } else if (isGazSelected.value) {
              energieGaz.value = true;
            } else {
              if (les_deux.value == true && fournisseur_elec.value == 'ENI' && fournisseur_gaz.value != 'ENI') {
                energieGaz.value = true;
              }
              else if (les_deux.value == true && fournisseur_elec.value != 'ENI' && fournisseur_gaz.value == 'ENI') {
                energieElectricite.value = true;
              }
              else if (les_deux.value == true && fournisseur_elec.value != 'ENI' && fournisseur_gaz.value != 'ENI') {
                electricityGaz.value = true
              }


            }
            messageOffer2.value = true;
            currentMessage.value = 3;
          }, 8000);

          setTimeout(() => {
            messageOffer3.value = true;
            currentMessage.value = 4;
          }, 10000);

          setTimeout(() => {
            messageOffer4.value = true;
            currentMessage.value = 5;
          }, 12000);

          setTimeout(() => {
            messageOffer5.value = true;
            currentMessage.value = 6;
          }, 14000);

          setTimeout(() => {
            messageOffer6.value = true;
            currentMessage.value = 7;
          }, 16000);

          setTimeout(() => {
            messageOffer7.value = true;
            currentMessage.value = 8;
          }, 18000);

          setTimeout(() => {
            messageOffer8.value = true;
            currentMessage.value = 9;
          }, 19000);
        }

        answerChosen.value = null

        historyMessage.value = { role: "bot", welcomeMsg: [], question: firstQuestion.value, suggested_answers: suggested_answers.value, affirmation: botAnswer.value }
        return historyMessage, isCodePostalForm, isOfferForm, offer

      }, 1500);

      scrollToBottom();
      return historyMessage, isCodePostalForm, isOfferForm
    } else {
      isOfferForm.value = true
      showCGUmsg.value = true
    }

    window.removeEventListener('mousemove', resetActivityTimeout);
    window.removeEventListener('keydown', resetActivityTimeout);
  }
  else {

    if ((answer == 'Oui' || answer == 'Non') && firstQuestion.value == 'Parfait ! pouvez-vous m’ indiquer le code postal de votre commune, s’il vous plaît ? ') {
      modalOui.value = true
      showAnswerButton.value = false
    }
    showQuestion.value = false

    if (firstQuestion.value == ' En plus de sécuriser votre budget, pourquoi changer de fournisseur ?') {
      motivation.value = answer
      beginDiscussion(firstQuestion.value, answer)
    } else {
      tracking_action(conversation_id.value, visitor_id.value, firstQuestion.value, answer)
    }

    answerChosen.value = answer
    chatHistory.value.push(historyMessage.value)
    chatHistory.value.push({ role: "user", content: answer })
    localStorage.setItem('chatHistory', chatHistory)
    beginMsg.value = []
    showAnswerLoader.value = true
    showBotAnswer.value = false
    setTimeout(() => {
      showAnswerLoader.value = false
      showBotAnswer.value = true


      if (goNext.value == true) {
        if (firstQuestion.value == 'Renseignez vos coordonnées et découvrez votre offre !') {
          weight.value++
        } else {
          weight.value = 5
        }
      } else {
        weight.value++
      }

      switch (answerChosen.value) {
        case 'Electricité':
        case 'Gaz':
          energietype.value = answerChosen.value;
          if (isMoving.value) {
            botAnswer.value = []
            weight.value = 4;
          } else {
            botAnswer.value = ['Encore quelques questions pour finaliser notre recherche…'];
          }
          break;
        case 'Les deux':
          energietype.value = answerChosen.value;
          if (isMoving.value) {
            botAnswer.value = []
            weight.value = 4;
          } else {
            botAnswer.value = ['Encore quelques questions pour finaliser notre recherche…'];
            les_deux.value = true
          }
          break;


        case 'Une énergie verte':
          botAnswer.value = ['C\'est noté, trouvons ensemble l\'offre qui va vous permettre de bénéficier d\'une énergie verte...']
          break
        case 'Un service client disponible':
          botAnswer.value = ['C\'est noté, trouvons ensemble l\'offre qui va vous permettre de bénéficier d\'un service client disponible...']
          break
        case 'J\'emménage':
          demenagement.value = answerChosen.value
          botAnswer.value = ['C\'est noté, trouvons ensemble l\'offre qui va vous permettre de vous installer en toute tranquilité...']
          isMoving.value = true;
          break
        case 'Autre':
          botAnswer.value = ['C\'est noté, allons plus loin pour mieux comprendre vos besoins...']
          break


        case 'Moins de 100 €':
        case 'Entre 100 et 150 €':
        case 'Entre 150 et 200 €':
        case 'Je ne sais pas':
          price.value = answerChosen.value
          break


        case 'AUTRE':
          botAnswer.value = []
          if (energietype.value == 'Gaz') {
            fournisseur_gaz.value = answerChosen.value
          }
          else if (energietype.value == 'Electricité') {
            fournisseur_elec.value = answerChosen.value
          }
          else if (energietype.value == 'Les deux' && firstQuestion.value == 'Et vous êtes chez qui pour le gaz ?') {
            fournisseur_gaz.value = answerChosen.value
          }
          else if (energietype.value == 'Les deux' && firstQuestion.value == 'Et vous êtes chez qui pour l’électricité ?') {
            fournisseur_elec.value = answerChosen.value
          }
          break


        case 'ENGIE':
          botAnswer.value = []
          if (energietype.value == 'Gaz') {
            fournisseur_gaz.value = answerChosen.value
          }
          else if (energietype.value == 'Electricité') {
            fournisseur_elec.value = answerChosen.value
          }
          else if (energietype.value == 'Les deux' && firstQuestion.value == 'Et vous êtes chez qui pour le gaz ?') {
            fournisseur_gaz.value = answerChosen.value
          }
          else if (energietype.value == 'Les deux' && firstQuestion.value == 'Et vous êtes chez qui pour l’électricité ?') {
            fournisseur_elec.value = answerChosen.value
          }
          break


        case 'TOTAL ENERGIES':
          botAnswer.value = []
          if (energietype.value == 'Gaz') {
            fournisseur_gaz.value = answerChosen.value
          }
          else if (energietype.value == 'Electricité') {
            fournisseur_elec.value = answerChosen.value
          }
          else if (energietype.value == 'Les deux' && firstQuestion.value == 'Et vous êtes chez qui pour le gaz ?') {
            fournisseur_gaz.value = answerChosen.value
          }
          else if (energietype.value == 'Les deux' && firstQuestion.value == 'Et vous êtes chez qui pour l’électricité ?') {
            fournisseur_elec.value = answerChosen.value
          }
          break


        case 'ENI':
          botAnswer.value = []
          if (energietype.value == 'Gaz') {
            fournisseur_gaz.value = answerChosen.value
          }
          else if (energietype.value == 'Electricité') {
            fournisseur_elec.value = answerChosen.value
          }
          else if (energietype.value == 'Les deux' && firstQuestion.value == 'Et vous êtes chez qui pour le gaz ?') {
            fournisseur_gaz.value = answerChosen.value
          }
          else if (energietype.value == 'Les deux' && firstQuestion.value == 'Et vous êtes chez qui pour l’électricité ?') {
            fournisseur_elec.value = answerChosen.value
          }
          break


        case 'EDF':
          botAnswer.value = []
          if (energietype.value == 'Gaz') {
            fournisseur_gaz.value = answerChosen.value
          }
          else if (energietype.value == 'Electricité') {
            fournisseur_elec.value = answerChosen.value
          }
          else if (energietype.value == 'Les deux' && firstQuestion.value == 'Et vous êtes chez qui pour le gaz ?') {
            fournisseur_gaz.value = answerChosen.value
          }
          else if (energietype.value == 'Les deux' && firstQuestion.value == 'Et vous êtes chez qui pour l’électricité ?') {
            fournisseur_elec.value = answerChosen.value
          }
          break


        case 'SOWEE':
          botAnswer.value = []
          if (energietype.value == 'Gaz') {
            fournisseur_gaz.value = answerChosen.value
          }
          else if (energietype.value == 'Electricité') {
            fournisseur_elec.value = answerChosen.value
          }
          else if (energietype.value == 'Les deux' && firstQuestion.value == 'Et vous êtes chez qui pour le gaz ?') {
            fournisseur_gaz.value = answerChosen.value
          }
          else if (energietype.value == 'Les deux' && firstQuestion.value == 'Et vous êtes chez qui pour l’électricité ?') {
            fournisseur_elec.value = answerChosen.value
          }
          break

      }

      switch (weight.value) {

        case 1:

          firstQuestion.value = 'Et sur quelle énergie souhaitez-vous que l\'on regarde ?';
          suggested_answers.value = [
            { answer: 'Electricité', answerImg: require('@/assets/electricity.png') },
            { answer: 'Gaz', answerImg: require('@/assets/gaz.png') },
            { answer: 'Les deux', answerImg: require('@/assets/les_deux.png') },
          ];

          break;

        case 2:

          if (les_deux.value) {
            lesDeux()

          } else {
            firstQuestion.value = 'Et vous êtes chez qui actuellement ?';
            suggested_answers.value = [
              { answer: 'ENGIE', answerImg: require('@/assets/logo_engie.png') },
              { answer: 'TOTAL ENERGIES', answerImg: require('@/assets/logo_total.png') },
              { answer: 'ENI', answerImg: require('@/assets/logo_eni.png') },
              { answer: 'EDF', answerImg: require('@/assets/logo_edf.png') },
              { answer: 'SOWEE', answerImg: require('@/assets/logo_sowee.png') },
              { answer: 'AUTRE', answerImg: require('@/assets/logo_autre.png') },
            ];

          }
          break;
        case 3:
          firstQuestion.value = 'Actuellement, à combien s\'élève vos factures mensuelles ?  🙂'
          suggested_answers.value = [
            { answer: 'Moins de 100 €', answerImg: null },
            { answer: 'Entre 100 et 150 €', answerImg: null },
            { answer: 'Entre 150 et 200 €', answerImg: null },
            { answer: 'Je ne sais pas', answerImg: null },
          ]
          break
        case 4:
          firstQuestion.value = 'Parfait ! pouvez-vous m’ indiquer le code postal de votre commune, s’il vous plaît ? ';
          suggested_answers.value = [{ answer: 'SUIVANT', answerImg: null }];
          break

      }





      if (botAnswer.value.length > 0) {

        if (botAnswer.value[0] == 'C\'est noté, trouvons ensemble l\'offre qui va vous permettre de bénéficier d\'une énergie verte...' || botAnswer.value[0] == 'C\'est noté, trouvons ensemble l\'offre qui va vous permettre de bénéficier d\'un service client disponible...' || botAnswer.value[0] == 'C\'est noté, trouvons ensemble l\'offre qui va vous permettre de vous installer en toute tranquilité...' || botAnswer.value[0] == 'C\'est noté, allons plus loin pour mieux comprendre vos besoins...') {
          showAffirmation.value = botAnswer.value[0]
          showAffirmation1.value = false
          showAffirmation2.value = false
          showAffirmation3.value = true
          showAffirmation4.value = false
          showAffirmation5.value = false
          showAffirmation6.value = false
          showAffirmation7.value = false
          showAffirmation8.value = false
        }
        if (botAnswer.value[0] == 'Encore quelques questions pour finaliser notre recherche…') {
          showAffirmation.value = botAnswer.value[0]
          showAffirmation1.value = false
          showAffirmation2.value = false
          showAffirmation3.value = true
          showAffirmation4.value = false
          showAffirmation5.value = false
          showAffirmation6.value = false
          showAffirmation7.value = false
          showAffirmation8.value = false

        }

      }
      setTimeout(() => {
        showQuestion.value = true
      }, botAnswer.value.length * 1500)
      // isCodePostalForm.value = false
      if (firstQuestion.value.indexOf('code postal') !== -1) {
        isCodePostalForm.value = true
      }

      if (firstQuestion.value.indexOf('découvrez votre offre') !== -1) {
        isOfferForm.value = true
      }

      answerChosen.value = null

      historyMessage.value = { role: "bot", welcomeMsg: [], question: firstQuestion.value, suggested_answers: suggested_answers.value, affirmation: botAnswer.value }
      scrollToBottom();
      //setIntervalRelance()
      return historyMessage, isCodePostalForm, isOfferForm
      // })
    }, 1500);
  }
}

const resetActivityTimeout = () => {
  count.value = 0;
  // Réinitialiser le minuteur de vérification d'inactivité
  clearTimeout(setIntervalDelete.value);
  // Redémarrer le minuteur de vérification d'inactivité
  setIntervalDelete.value = setInterval(() => {
    count.value++;
    switch (count.value) {
      case 45:
        openRelanceModal.value = true;
        relance1.value = true;
        relance2.value = false;
        relance3.value = false;
        break;
      case 90:
        openRelanceModal.value = true;
        relance2.value = true;
        relance3.value = false;
        relance1.value = false;
        relance3.value = false;
        break;
      case 420:
        openRelanceModal.value = true;
        relance2.value = false;
        relance1.value = false;
        relance3.value = true;
        clearInterval(setIntervalDelete.value);
        break;
    }
  }, 1000);
};

const lesDeux = async () => {
  switch (currentEnergySelection.value) {
    case 0:
      firstQuestion.value = 'Et vous êtes chez qui pour l’électricité ?';
      suggested_answers.value = [
        { answer: 'ENGIE', answerImg: require('@/assets/logo_engie.png') },
        { answer: 'TOTAL ENERGIES', answerImg: require('@/assets/logo_total.png') },
        { answer: 'ENI', answerImg: require('@/assets/logo_eni.png') },
        { answer: 'EDF', answerImg: require('@/assets/logo_edf.png') },
        { answer: 'SOWEE', answerImg: require('@/assets/logo_sowee.png') },
        { answer: 'AUTRE', answerImg: require('@/assets/logo_autre.png') },
      ];

      currentEnergySelection.value = 1;
      weight.value = 1;
      break;
    case 1:
      firstQuestion.value = 'Et vous êtes chez qui pour le gaz ?';
      suggested_answers.value = [
        { answer: 'ENGIE', answerImg: require('@/assets/logo_engie.png') },
        { answer: 'TOTAL ENERGIES', answerImg: require('@/assets/logo_total.png') },
        { answer: 'ENI', answerImg: require('@/assets/logo_eni.png') },
        { answer: 'EDF', answerImg: require('@/assets/logo_edf.png') },
        { answer: 'SOWEE', answerImg: require('@/assets/logo_sowee.png') },
        { answer: 'AUTRE', answerImg: require('@/assets/logo_autre.png') },
      ];
      break;
  }
}

// Vérifier si une activité est en cours sur la page
window.addEventListener('mousemove', resetActivityTimeout);
window.addEventListener('keydown', resetActivityTimeout);


</script>


<style src="./style.css"></style>
